<section class="navbar-custom2">
    <div class="container">
        <div id="carousel-navbar">
            <template *ngFor="let section of sections; let i = index">
                <div (click)="scroll(section.slug)" class="item-navbar-custom2 item-directorio-administracion"
                    *ngIf="section.slug != 'i-now' && section.slug != 'laboratorio-de-innovacion'">
                    <h6 class="title-navbar-custom">{{ section.titulo }}</h6>
                </div>
            </template>
        </div>
    </div>
</section>
<div class="bg-white bg-white-custom">
    <!-- <div class="section-banner-custom-new" id="section0" style="background-image: url(assets/img/img-test2.jpg);">
        <div class="overlay-banner-custom-new"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="title-banner-custom-new" data-aos="fade-right" data-aos-duration="700">Innovación
                    </h2>
                    <span class="line-title-custom-new-banner" data-aos="fade-right" data-aos-duration="700"
                        data-aos-delay="400"></span>
                </div>
            </div>
        </div>
    </div> -->
    <div class="section-banner-custom-new p-0 d-none" id="section0">
        <div class="overlay-banner-custom-new"></div>
        <!-- <a [routerLink]="['/formulario-desafio7inow']" class="link-to-from7"><img src="assets/img/banner-innovacion.jpg"
                alt=""></a> -->
        <span class="link-to-from7"><img
                src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/01/Ganadores-3-v1.jpg" alt=""></span>
    </div>
    <div id="animationWindow"></div>
    <section id="breadcrumbs">
        <div class="container">
            <div class="breadcrumbs breadcrumbs-sections">
                <div class="breadcrumbs-item">
                    <a [routerLink]="['/muro']"><span class="material-icons">
                            home
                        </span></a>
                    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
                </div>
                <div class="breadcrumbs-item">
                    <span>AbastibleTec</span>
                </div>
            </div>
        </div>
    </section>
    <div *ngFor="let section of sections; let i = index" class="sections-custom-new">
        <section class="section-custom-new" id="{{section.slug}}"
            *ngIf="section.slug != 'i-now' && section.slug != 'laboratorio-de-innovacion'">
            <div class="container">
                <div class="row">
                    <div class="" [ngClass]="{'col-lg-3': section.video, 'col-lg-4': !section.video }">
                        <div class="box-title-custom-new">
                            <h3 class="title-custom-new" data-aos="fade-up" data-aos-duration="700">{{ section.titulo }}
                            </h3>
                            <span class="line-title-custom-new" data-aos="fade-right" data-aos-duration="700"
                                data-aos-delay="400"></span>
                        </div>
                    </div>
                    <div class="" [ngClass]="{'col-lg-6': section.video, 'col-lg-8': !section.video }">
                        <div class="box-content-custom-new" data-aos="fade-up" data-aos-duration="700"
                            data-aos-delay="200">
                            <div class="text-custom-new">
                                <p [innerHTML]="textMin(section.descripcion_corta)" *ngIf="section.no_enlace != 1"></p>
                                <p [innerHTML]="section.descripcion_corta" *ngIf="section.no_enlace == 1"></p>
                            </div>
                            <span
                                (click)="fullTextModal(section.titulo , section.texto , section.slug,section.documentos)"
                                target="_blank" class="btn-custom-new"
                                *ngIf="!section.enlace_blank && section.no_enlace != 1">
                                <span>VER MÁS</span>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path
                                                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <a (click)="goToLink(section.enlace_blank)" class="btn-custom-new"
                                *ngIf="section.enlace_blank">
                                <span>VER MÁS</span>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                                    <g>
                                        <g>
                                            <path
                                                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-img-section-custom-new" *ngIf="section.video">
                <div class="diagonal-cut"></div>
                <video autoplay muted loop [muted]="true" *ngIf="section.titulo != 'i-NOW'">
                    <source src="{{ section.video }}">
                </video>
                <img *ngIf="section.titulo == 'i-NOW'" style="object-position: 85%;" src="{{ section.video }}" alt="">
            </div>
            <div class="box-img-section-custom-new" *ngIf="section.imagen">
                <div class="diagonal-cut"></div>
                <img [src]="section.imagen" alt="" class="img-fluid">
            </div>
        </section>

        <section class="section-image-divider" *ngIf="section.imagen_separacion"
            [ngStyle]="{ 'background-image': 'url(' + section.imagen_separacion + ')'}">
        </section>
    </div>
    <div class="tabs-search">
        <button data-tab="#news-general" class="item-tab-search active"
            *ngIf="news3 && news3.length > 0">Noticias</button>
        <button data-tab="#prensa-general" class="item-tab-search" *ngIf="news2 && news2.length > 0">Prensa</button>
        <button data-tab="#galerias-general" class="item-tab-search"
            *ngIf="galerias && galerias.length > 0">Galerías</button>
        <button data-tab="#videos-general" class="item-tab-search"
            *ngIf="videosAbastibleTec && videosAbastibleTec.length > 0">Videos</button>
    </div>
    <div class="tabs-content-search">
        <div class="item-tab-content-search" id="news-general" *ngIf="news3 && news3.length > 0">
            <section class="section-custom5">
                <div class="container">
                    <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">NOTICIAS</h3>
                    <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
                    <div class="swiper-container" #carouselNoticias [swiper]="carouselGalerias.config">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let new3 of news3; let i = index">
                                <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                                    <div class="card-img">
                                        <img src="{{ new3.img }}" alt="">
                                        <div class="diagonal-cut"></div>
                                    </div>
                                    <div class="card-content">
                                        <h4 class="card-title" [innerHTML]="new3.title"></h4>
                                        <div class="card-text">
                                            <p [innerHTML]="new3.text"></p>
                                        </div>
                                        <a [routerLink]="['/noticia/' + new3.slug ]" class="card-link">Leer más</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-prev">
                            <span class="material-icons">
                                keyboard_arrow_left
                            </span>
                        </div>
                        <div class="swiper-button-next">
                            <span class="material-icons">
                                keyboard_arrow_right
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700"
                            data-aos-delay="100">
                            <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'i-now' }"
                                class="btn-custom-new">
                                <span>VER MÁS</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="item-tab-content-search" id="prensa-general" style="display: none;"
            *ngIf="news2 && news2.length > 0">
            <section class="section-custom5" id="noticias">
                <div class="container">
                    <!-- <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">NOTICIAS
                                    </h3>
                                    <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
                                        data-aos-delay="100"></span>-->
                    <div class="swiper-container" [swiper]="carouselGalerias2.config">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let new2 of news2; let i = index">
                                <div class="card-design-notice" data-aos="fade-up" data-aos-duration="700">
                                    <div class="card-img">
                                        <img src="{{ new2.img }}" alt="">
                                        <div class="diagonal-cut"></div>
                                    </div>
                                    <div class="card-content">
                                        <h4 class="card-title" [innerHTML]="new2.title"></h4>
                                        <div class="card-text">
                                            <p
                                                [innerHTML]="(new2.text.length>120) ? (new2.text | slice:0:120)+'..' : (new2.text)">
                                            </p>
                                        </div>
                                        <a [routerLink]="['/noticia/' + new2.slug ]" class="card-link">Leer más</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-prev">
                            <span class="material-icons">
                                keyboard_arrow_left
                            </span>
                        </div>
                        <div class="swiper-button-next">
                            <span class="material-icons">
                                keyboard_arrow_right
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"
                            class="col-12 box-btn-news">
                            <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'soluciones-energeticas' }"
                                class="btn-custom-new btn-more-ideas"><span>VER TODAS LAS
                                    NOTICIAS</span></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="item-tab-content-search" id="galerias-general" style="display: none;"
            *ngIf="galerias && galerias.length > 0">
            <section class="section-custom5">
                <div class="container">
                    <!-- <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">Galerías</h3>
                    <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span> -->
                    <div class="swiper-container mb-4" #carouselGaleriasOne [swiper]="carouselGalerias6.config">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let item of galerias; let i = index">
                                <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                                    class="card-design-notice card-design-notice-nm card-design-embajadores"
                                    data-aos="fade-up" data-aos="fade-up" data-aos-duration="700">
                                    <div class="card-img">
                                        <img src="{{ item.feature_img }}" alt="">
                                        <div class="diagonal-cut"></div>
                                    </div>
                                    <div class="card-content">
                                        <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="swiper-button-prev">
                            <span class="material-icons">
                                keyboard_arrow_left
                            </span>
                        </div>
                        <div class="swiper-button-next">
                            <span class="material-icons">
                                keyboard_arrow_right
                            </span>
                        </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        <a [routerLink]="['/galerias/galerias_inow' ]" class="btn-custom-new">
                            <span>VER MÁS</span>
                        </a>
                    </div>
                </div>
            </section>
        </div>
        <div class="item-tab-content-search" id="videos-general" style="display: none;"
            *ngIf="videosAbastibleTec && videosAbastibleTec.length > 0">
            <section class="section-custom5">
                <div class="container">
                    <div class="swiper-container" [swiper]="carouselGalerias2.config">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let videoInow of videosAbastibleTec; let i = index">
                                <div class="card-design-notice card-design-embajadores item-video-galeria"
                                    data-aos="fade-up" (click)="showModalVideo($event, videoInow.video_url)"
                                    data-aos-duration="700">
                                    <div class="card-img">
                                        <img [src]="videoInow.img" alt="">
                                        <div class="diagonal-cut"></div>
                                        <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                            alt="" class="btn-play-desing1">
                                    </div>
                                    <div class="card-content">
                                        <h4 class="card-title" [innerHtml]="videoInow.title"></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-prev">
                            <span class="material-icons"> keyboard_arrow_left </span>
                        </div>
                        <div class="swiper-button-next">
                            <span class="material-icons"> keyboard_arrow_right </span>
                        </div>
                    </div>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        <a [routerLink]="['/galeria-videos-section/i-now' ]" [queryParams]="1" class="btn-custom-new">
                            <span>VER MÁS</span>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    </div>

</div>
<!-- <a [routerLink]="['/formulario-desafio6' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg" alt=""><span>Cuéntanos
    tu idea</span></a> -->
<div class="modal-img-full modal-single-content" [ngClass]="modalTextShow == true ? 'active-modal' : ''">
    <div class="overlay-modal" (click)="closeFullTextModal()"></div>
    <div class="box-modal-text-full">
        <div class="modal-header-single-content">
            <button class="btn-back-single-content" (click)="closeFullTextModal()">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"
                    style="enable-background:new 0 0 512 512;" xml:space="preserve" fill="#ff7000">
                    <g>
                        <g>
                            <path
                                d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
                        </g>
                    </g>
                </svg>
            </button>
            <!-- <h3 class="title-header-modal">Innovación / <span [innerHTML]="modalTitulo"></span></h3> -->
            <h3 class="title-header-modal"><span [innerHTML]="modalTitulo"></span></h3>
        </div>
        <div class="modal-text">
            <div class="modal-content-text" [ngClass]="modalTitulo == 'i-NOW' ? 'modal-inow' : ''">
                <div class="modal-content-banner-inow" *ngIf="modalTitulo == 'i-NOW'">
                    <div class="modal-content-banner">
                        <div class="swiper-container" [swiper]="carouselBanner.config">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <!-- <a [routerLink]="['/ideas-desafio7']" class="link-banner-inow"><img
                                            src="assets/img/banner-inow-desafio7.jpg" alt=""></a> -->
                                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/01/Ganadores-4-v1.jpg"
                                        alt="">
                                </div>
                                <div class="swiper-slide">
                                    <!-- <a [routerLink]="['/ideas-desafio7']" class="link-banner-inow"><img
                                            src="assets/img/banner-inow-desafio7.jpg" alt=""></a> -->
                                    <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2023/01/Honrosa-2-v1.jpg"
                                        alt="">
                                </div>
                                <div class="swiper-slide">
                                    <a [routerLink]="['/desafios']" class="link-banner-inow"><img
                                            src="https://intranet.abastible.cl/wp/wp-content/uploads/2022/06/banner-inow1.jpg"
                                            alt=""></a>
                                </div>
                                <div class="swiper-slide">
                                    <a [routerLink]="['/embajadores']" class="link-banner-inow"><img
                                            src="https://intranet.abastible.cl/wp/wp-content/uploads/2022/06/banner-inow2.jpg"
                                            alt=""></a>
                                </div>
                                <div class="swiper-slide">
                                    <a [routerLink]="['/galeria-videos-section-inow/i-now-talk']"
                                        class="link-banner-inow"><img
                                            src="https://intranet.abastible.cl/wp/wp-content/uploads/2022/06/banner-inow3.jpg"
                                            alt=""></a>
                                </div>
                            </div>
                            <div class="swiper-button-prev">
                                <span class="material-icons">
                                    keyboard_arrow_left
                                </span>
                            </div>
                            <div class="swiper-button-next">
                                <span class="material-icons">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div [innerHTML]="newContentModal"></div>
                <div class="container" *ngIf="modalTitulo == 'i-NOW'" id="embajadores">
                    <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">EMBAJADORES
                    </h3>
                    <span class="line-title-custom-new mx-auto mb-5" data-aos="fade-up" data-aos-duration="700"
                        data-aos-delay="100"></span>
                    <marquee behavior="loop" direction="" class="marquee-list">
                        <div class="marquee-list-custom1">
                            <div class="card-design-notice card-design-notice-nm card-design-embajadores"
                                data-aos="fade-up" data-aos-duration="700"
                                *ngFor="let embajador of embajadores; let i = index">
                                <div class="card-img">
                                    <img src="{{ embajador.img }}" alt="" style="object-position: 50% 30%;">
                                    <div class="diagonal-cut"></div>
                                </div>
                                <div class="card-content">
                                    <h4 class="card-title" [innerHTML]="embajador.title"></h4>
                                    <div class="card-text">
                                        <p [innerHTML]="embajador.content"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </marquee>
                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                        <a [routerLink]="['/embajadores' ]" class="btn-custom-new">
                            <span>VER MÁS</span>
                        </a>
                    </div>
                </div>
                <div *ngIf="modalTitulo == 'i-NOW'">
                    <!-- Documentos -->
                    <div *ngIf="modalDocuments.length > 0" class=" pb-3">
                        <div class="tabs-search">
                            <button data-tab="#documentos-innovacion" class="item-tab-search active">Documentos</button>
                        </div>
                        <div class="tabs-content-search">
                            <div class="item-tab-content-search" id="documentos-innovacion">
                                <div class="col-lg-8 mx-auto mb-5">
                                    <div class="content-documents-custom-new3">
                                        <a href="{{document.link}}" class="item-document-custom-new3" target="_blank"
                                            data-aos="fade-up" data-aos-duration="700" data-aos-delay="0"
                                            *ngFor="let document of modalDocuments; let i = index">
                                            <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                                            <div class="box-acctions-custom-new3">
                                                <span class="view-document">
                                                    <img src="assets/img/file.svg" alt="">
                                                </span>
                                                <span class="download-document">
                                                    <img src="assets/img/download.svg" alt="">
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="modalTitulo == 'i-NOW'">
                    <div class="tabs-search">
                        <button data-tab="#news-i-now" class="item-tab-search active">Noticias</button>
                        <button data-tab="#talks-i-now" class="item-tab-search">i-NOW TALKS</button>
                        <button data-tab="#videos-i-now" class="item-tab-search ">Videos</button>
                        <button data-tab="#galeries-i-now" class="item-tab-search ">Galería</button>
                    </div>
                    <div class="tabs-content-search">
                        <div class="item-tab-content-search" id="news-i-now">
                            <section class="section-custom5">
                                <div class="container">
                                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                                                <div class="card-design-notice" data-aos="fade-up"
                                                    data-aos-duration="700">
                                                    <div class="card-img">
                                                        <img src="{{ new1.img }}" style="object-position: center;"
                                                            alt="">
                                                        <div class="diagonal-cut"></div>
                                                    </div>
                                                    <div class="card-content">
                                                        <h4 class="card-title" [innerHTML]="new1.title"></h4>
                                                        <div class="card-text">
                                                            <p [innerHTML]="new1.text"></p>
                                                        </div>
                                                        <a [routerLink]="['/noticia/' + new1.slug ]"
                                                            class="card-link">Leer más</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-button-prev">
                                            <span class="material-icons">keyboard_arrow_left</span>
                                        </div>
                                        <div class="swiper-button-next">
                                            <span class="material-icons">keyboard_arrow_right</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"
                                            class="col-12 box-btn-news">
                                            <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'i-now' }"
                                                class="btn-custom-new"><span>VER MÁS</span></a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="item-tab-content-search" id="talks-i-now" style="display: none;">
                            <section class="section-custom5">
                                <div class="container">
                                    <div class="swiper-container" [swiper]="carouselCharlas.config">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" *ngFor="let inowTalk of inowTalks; let i = index">
                                                <div class="card-design-notice card-design-notice-nm card-design-embajadores item-galeria-youtube item-design-phone"
                                                    data-aos="fade-up" data-aos="fade-up" data-aos-duration="700"
                                                    (click)="showModalVideoIframe($event, inowTalk.video_url)">
                                                    <img src="assets/img/phone.png" alt="" class="img-phone-design1">
                                                    <div class="card-img">
                                                        <img [src]="inowTalk.img" alt="">
                                                        <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                                            alt="" class="btn-play-desing1">
                                                    </div>
                                                    <div class="card-content">
                                                        <h4 class="card-title" [innerHTML]="inowTalk.title"></h4>
                                                        <div class="card-text" *ngIf="inowTalk.content">
                                                            <p [innerHTML]="inowTalk.content" class="text-center"></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-button-prev">
                                            <span class="material-icons"> keyboard_arrow_left </span>
                                        </div>
                                        <div class="swiper-button-next">
                                            <span class="material-icons"> keyboard_arrow_right </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"
                                            class="col-12 box-btn-news">
                                            <a [routerLink]="['/galeria-videos-section-inow/i-now-talk' ]"
                                                [queryParams]="1" class="btn-custom-new">
                                                <span>VER MÁS</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="item-tab-content-search" id="videos-i-now" style="display: none;">
                            <section class="section-custom5">
                                <div class="container">
                                    <div class="swiper-container" [swiper]="carouselGalerias2.config">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide"
                                                *ngFor="let videoInow of videosInow; let i = index">
                                                <div class="card-design-notice card-design-embajadores item-video-galeria"
                                                    data-aos="fade-up"
                                                    (click)="showModalVideo($event, videoInow.video_url)"
                                                    data-aos-duration="700">
                                                    <div class="card-img">
                                                        <img [src]="videoInow.img" alt="">
                                                        <div class="diagonal-cut"></div>
                                                        <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                                            alt="" class="btn-play-desing1">
                                                    </div>
                                                    <div class="card-content">
                                                        <h4 class="card-title" [innerHtml]="videoInow.title"></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-button-prev">
                                            <span class="material-icons"> keyboard_arrow_left </span>
                                        </div>
                                        <div class="swiper-button-next">
                                            <span class="material-icons"> keyboard_arrow_right </span>
                                        </div>
                                    </div>
                                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700"
                                        data-aos-delay="100">
                                        <a [routerLink]="['/galeria-videos-section/i-now' ]" [queryParams]="1"
                                            class="btn-custom-new">
                                            <span>VER MÁS</span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="item-tab-content-search" id="galeries-i-now" style="display: none;">
                            <section class="section-custom5">
                                <div class="container">
                                    <div class="swiper-container" [swiper]="carouselGalerias3.config">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" *ngFor="let item of galerias; let i = index">
                                                <a [routerLink]="['/galeria/' + item.ID ]"
                                                    [queryParams]="{title: item.post_title }"
                                                    class="card-design-notice card-design-notice-nm card-design-embajadores"
                                                    data-aos="fade-up" data-aos="fade-up" data-aos-duration="700">
                                                    <div class="card-img">
                                                        <img src="{{ item.feature_img }}" class="m-0 w-100" alt="">
                                                        <div class="diagonal-cut"></div>
                                                    </div>
                                                    <div class="card-content">
                                                        <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="swiper-button-prev">
                                            <span class="material-icons"> keyboard_arrow_left </span>
                                        </div>
                                        <div class="swiper-button-next">
                                            <span class="material-icons"> keyboard_arrow_right </span>
                                        </div>
                                    </div>
                                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700"
                                        data-aos-delay="100">
                                        <a [routerLink]="['/galerias/galerias_inow' ]" class="btn-custom-new">
                                            <span>VER MÁS</span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div *ngIf="modalTitulo == 'Soluciones Energéticas'">
                    <!-- Documentos -->
                    <div *ngIf="modalDocuments.length > 0" class="pt-5 pb-3">
                        <div class="tabs-search">
                            <button data-tab="#documentos-soluciones-energeticas"
                                class="item-tab-search active">Documentos</button>
                        </div>
                        <div class="tabs-content-search">
                            <div class="item-tab-content-search" id="documentos-soluciones-energeticas">
                                <div class="col-lg-8 mx-auto mb-5">
                                    <div class="content-documents-custom-new3">
                                        <a href="{{document.link}}" class="item-document-custom-new3" target="_blank"
                                            data-aos="fade-up" data-aos-duration="700" data-aos-delay="0"
                                            *ngFor="let document of modalDocuments; let i = index">
                                            <h3 class="title-document-custom-new3">{{document.titulo}}</h3>
                                            <div class="box-acctions-custom-new3">
                                                <span class="view-document">
                                                    <img src="assets/img/file.svg" alt="">
                                                </span>
                                                <span class="download-document">
                                                    <img src="assets/img/download.svg" alt="">
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="modalTitulo == 'Soluciones Energéticas'">
                    <div class="tabs-search">
                        <button data-tab="#testimoniales" class="item-tab-search active">Testimoniales</button>
                        <button data-tab="#news-search" class="item-tab-search "
                            *ngIf="news2.length > 0">Noticias</button>
                    </div>
                    <div class="tabs-content-search">
                        <div class="item-tab-content-search" id="testimoniales">
                            <section class="section-custom5">
                                <div class="container" id="charlas">
                                    <!-- <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/03/Testimoniales.png" alt=""
                                class="img-title-soluciones"> -->
                                    <div class="swiper-container" [swiper]="carouselGalerias.config">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide"
                                                *ngFor="let item of galeriaVideosTestimoniales; let i = index">
                                                <div class="card-design-notice card-design-embajadores item-video-galeria"
                                                    data-aos="fade-up" (click)="showModalVideo($event, item.video_url)"
                                                    data-aos-duration="700">
                                                    <div class="card-img">
                                                        <img [src]="item.img" alt="">
                                                        <div class="diagonal-cut"></div>
                                                        <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png"
                                                            alt="" class="btn-play-desing1">
                                                    </div>
                                                    <div class="card-content">
                                                        <h4 class="card-title" [innerHtml]="item.title"></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-button-prev">
                                            <span class="material-icons">
                                                keyboard_arrow_left
                                            </span>
                                        </div>
                                        <div class="swiper-button-next">
                                            <span class="material-icons">
                                                keyboard_arrow_right
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700"
                                        data-aos-delay="100">
                                        <a [routerLink]="['/galeria-videos-section/testimoniales-soluciones-energetica' ]"
                                            [queryParams]="1" class="btn-custom-new">
                                            <span>VER MÁS</span>
                                        </a>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="item-tab-content-search" id="news-search" style="display: none;">
                            <section class="section-custom5" id="noticias">
                                <div class="container">
                                    <!-- <h3 class="title-custom-new3 text-center" data-aos="fade-up" data-aos-duration="700">NOTICIAS
                            </h3>
                            <span class="line-title-custom-new mx-auto" data-aos="fade-up" data-aos-duration="700"
                                data-aos-delay="100"></span>-->
                                    <div class="swiper-container" [swiper]="carouselGalerias2.config">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" *ngFor="let new2 of news2; let i = index">
                                                <div class="card-design-notice" data-aos="fade-up"
                                                    data-aos-duration="700">
                                                    <div class="card-img">
                                                        <img src="{{ new2.img }}" alt="">
                                                        <div class="diagonal-cut"></div>
                                                    </div>
                                                    <div class="card-content">
                                                        <h4 class="card-title" [innerHTML]="new2.title"></h4>
                                                        <div class="card-text">
                                                            <p
                                                                [innerHTML]="(new2.text.length>120) ? (new2.text | slice:0:120)+'..' : (new2.text)">
                                                            </p>
                                                        </div>
                                                        <a [routerLink]="['/noticia/' + new2.slug ]"
                                                            class="card-link">Leer más</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="swiper-button-prev">
                                            <span class="material-icons">
                                                keyboard_arrow_left
                                            </span>
                                        </div>
                                        <div class="swiper-button-next">
                                            <span class="material-icons">
                                                keyboard_arrow_right
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"
                                            class="col-12 box-btn-news">
                                            <a [routerLink]="['/noticias']"
                                                [queryParams]="{categoryslug: 'soluciones-energeticas' }"
                                                class="btn-custom-new btn-more-ideas"><span>VER TODAS LAS
                                                    NOTICIAS</span></a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <footer>
                    <div class="menu-mobile">
                        <div class="content-menu-mobile">
                            <a href="#menu-mobile1" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                            <a href="#menu-mobile2" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                            <a href="#" class="btn-design-float"><span></span></a>
                            <a href="#menu-mobile3" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                            <a href="#menu-mobile4" class="link-collapse-menu-mobile"><img
                                    src="assets/img/icon-camera.svg" alt="" /></a>
                        </div>
                    </div>
                    <div class="container box-footer">
                        <div class="box-list-footer">
                            <div class="list-footer">
                                <h3 class="title-list-footer">Conoce Abastible</h3>
                                <ul>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="presentacion-de-la-empresa-historia">Presentación de
                                            la
                                            empresa/historia</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="mision-vision-valores">Misión -Visión - Valores</a>
                                    </li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="directorio-y-administracion">Directorio
                                            y Administración</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']"
                                            fragment="productos-y-servicios">Productos y Servicios</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']" fragment="proceso-productivo">Proceso
                                            Productivo</a></li>
                                    <li><a [routerLink]="['/conoce-abastible']" fragment="abastible-tec">Soluciones
                                            Energéticas</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="list-footer">
                                <h3 class="title-list-footer">Control Interno</h3>
                                <ul>
                                    <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1"
                                            target="_blank">Canal
                                            de
                                            Denuncias</a></li>
                                    <li><a [routerLink]="['/control-interno']" fragment="auditoria-interna">Auditoría
                                            interna</a></li>
                                    <li><a [routerLink]="['/control-interno']"
                                            fragment="seguridad-de-la-informacion">Seguridad de la
                                            información</a></li>
                                    <li><a [routerLink]="['/control-interno']" fragment="gestion-de-riesgo">Gestión
                                            de Riesgo</a></li>
                                    <li><a [routerLink]="['/control-interno']" fragment="gestion-de-procesos">Gestión de
                                            Procesos</a></li>
                                </ul>
                            </div>
                            <div class="list-footer">
                                <h3 class="title-list-footer">Seguridad</h3>
                                <ul>
                                    <li><a [routerLink]="['/seguridad']" fragment="integridad-operacional">Integridad
                                            Operacional</a></li>
                                    <li><a [routerLink]="['/seguridad']" fragment="gestion-de-calidad">Gestión de
                                            Calidad</a></li>
                                    <li><a [routerLink]="['/seguridad']"
                                            fragment="servicio-nacional-de-emergencias">Servicio Nacional de
                                            Emergencias</a></li>
                                    <li><a [routerLink]="['/seguridad']" fragment="manejo-manual-de-carga">Manejo Manual
                                            de Carga</a></li>
                                    <li><a [routerLink]="['/seguridad']" fragment="medio-ambiente">Medio Ambiente</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="list-footer">
                                <h3 class="title-list-footer">Beneficios</h3>
                                <ul>
                                    <li><a [routerLink]="['/beneficios']"
                                            fragment="beneficios-para-colaboradores">Beneficios para
                                            colaboradores</a></li>
                                    <li><a [routerLink]="['/beneficios']" fragment="clima-organizacional">Clima
                                            organizacional</a></li>
                                </ul>
                            </div>
                            <!--<div class="list-footer">
                                <h3 class="title-list-footer">Gobierno Corporativo</h3>
                                <ul>
                                  <li><a href="https://ca.deloitte-halo.com/whistleblower/website/canalAbastible?Pg=1" target="_blank">Canal de Denuncias</a></li>
                                  <li><a [routerLink]="['/gobierno-corporativo']" fragment="section2">Compliance</a></li>
                              </ul>
                            </div> -->
                            <div class="list-footer">
                                <h3 class="title-list-footer">Soluciones
                                    Energéticas</h3>
                                <ul>
                                    <li><a [routerLink]="['/abastible-tec']" fragment="abastible-tec">Soluciones
                                            Energéticas</a></li>
                                </ul>
                            </div>
                            <!-- <div class="list-footer">
                                <h3 class="title-list-footer">Innovación</h3>
                                <ul>
                                    <li><a [routerLink]="['/innovacion']" fragment="i-now">I-NOW</a></li>
                                    <li><a [routerLink]="['/innovacion']"
                                            fragment="laboratorio-de-innovacion">Laboratorio de Innovación</a>
                                    </li>
                                    <li><a [routerLink]="['/innovacion']" fragment="abastible-tec">Soluciones
                                            Energéticas</a></li>
                                </ul>
                            </div> -->
                            <div class="list-footer list-footer-rd">
                                <h3 class="title-list-footer">Social</h3>
                                <ul>
                                    <li><a href="https://www.facebook.com/AbastibleCL" target="_blank"><img
                                                src="assets/img/icons/icon-facebook.svg" alt="">AbastibleCL</a></li>
                                    <li><a href="https://www.instagram.com/laenergiadevivir/" target="_blank"><img
                                                src="assets/img/icons/icon-instagram.svg" alt="">/laenergiadevivir</a>
                                    </li>
                                    <li><a href="https://www.linkedin.com/company/abastible-s.a./" target="_blank"><img
                                                src="assets/img/icons/icon-linkedin.svg"
                                                alt="">/company/abastible-s.a.</a></li>
                                    <li><a href="https://www.youtube.com/user/AbastibleChileSA" target="_blank"><img
                                                src="assets/img/icons/icon-youtube.svg" alt="">/AbastibleChileSA</a>
                                    </li>
                                    <li><a href="https://www.abastible.cl/" target="_blank"><img
                                                src="assets/img/icons/logo-abastible.svg" alt="">abastible.cl</a></li>
                                </ul>
                            </div>
                        </div>
                        <a href="index.html" class="logo-footer"><img src="assets/img/logo.png" alt="" /></a>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom3-text">
    <div class="overlay-modal"></div>
    <div class="box-custom3-text">
        <button class="close">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="title-custom3-text">Levantamiento del proceso</h3>
        <div class="box-text-custome-text">
            <p>Entrevistas y reuniones de validación con los dueños y responsables del proceso con el objetivo de
                elaborar el
                informe de levantamiento de la situación actual y el diagrama de flujos de las actividades realizadas.
            </p>
        </div>
    </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
    <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
    <button class="close" (click)="closeModalVideoIframe()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="box-modal-video-full">
        <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
    <div class="overlay-modal" (click)="closeModalVideo()"></div>
    <button class="close" (click)="closeModalVideo()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="box-modal-video-full">
        <video width="800" [src]="modalSrcVideo" controls>
            Your browser does not support HTML5 video.
        </video>
    </div>
</div>

<!-- <a [routerLink]="['/formulario-desafio7inow' ]" class="btn-mailto-inow"><img src="assets/img/icons/mail.svg"
        alt=""><span>Cuéntanos
        tu idea</span></a> -->