<div *ngIf="participo == 3">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section3">
      </div>
    </div>
  </div>
</div>
<div *ngIf="participo == 1">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section1">
        <h3 class="page-trivia-ciberseguridad-before-content-section3-title">Ya has participado en la trivia</h3>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="myForm" *ngIf="participo == 0">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section1">
        <h3 class="page-trivia-ciberseguridad-before-content-section1-title" id="trivia-title-form1">Pregunta 1</h3>
        <h3 class="page-trivia-ciberseguridad-before-content-section1-title" id="trivia-title-form2">Pregunta 2</h3>
      </div>
      <div class="ancla-scroll1"></div>
      <div class="page-trivia-ciberseguridad-before-content-section2" id="trivia-form1">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/W5P0Wh5eOKc?si=Y0cw--tcM5XDVDS0"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="iframe-trivia"></iframe>
        <div class="page-trivia-ciberseguridad-before-content-section2-items">
          <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="answer1" class="page-trivia-ciberseguridad-before-content-section2-item-label">Respuesta</label>
            <textarea type="text" name="answer1" id="answer1" formControlName="answer1"
              class="page-trivia-ciberseguridad-before-content-section2-item-input" rows="5" cols="50"></textarea>
          </div>
        </div>
        <button type="button" class="page-trivia-ciberseguridad-before-content-section2-button"
          id="next-step">Siguiente</button>
      </div>
      <div class="page-trivia-ciberseguridad-before-content-section2" id="trivia-form2">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/BWw7yZJpOH4?si=op38DKLFiMwwSylq"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="iframe-trivia"></iframe>
        <div class="page-trivia-ciberseguridad-before-content-section2-items">
          <div class="page-trivia-ciberseguridad-before-content-section2-item">
            <label for="answer2" class="page-trivia-ciberseguridad-before-content-section2-item-label">Respuesta</label>
            <textarea type="text" name="answer2" id="answer2" formControlName="answer2"
              class="page-trivia-ciberseguridad-before-content-section2-item-input" rows="5" cols="50"></textarea>
          </div>
        </div>
        <button type="button" class="page-trivia-ciberseguridad-before-content-section2-button" id="submit-form">
          ENVIAR RESPUESTAS
        </button>
      </div>
      <div class="page-trivia-ciberseguridad-content-section5">
        <img src="assets/img/enemigo-secreto/logos-abajo-transparent.png" alt=""
          class="page-trivia-ciberseguridad-content-section5-img">
      </div>
    </div>
  </div>

</form>
<div *ngIf="participo == 4">
  <div class="page-trivia-ciberseguridad-before">
    <div class="page-trivia-ciberseguridad-before-content">
      <div class="page-trivia-ciberseguridad-before-content-section1">
        <div class="ancla-scroll2"></div>
        <h3 class="page-trivia-ciberseguridad-before-content-section3-title">Tu respuesta ha sido enviada existosamente
        </h3>
      </div>
    </div>
  </div>
</div>