import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { environment2 } from '../../environments/environment2';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  constructor(private httpClient: HttpClient) { }

  getPost(slug: string): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-post-new`,
        {
          slug: slug,
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  getPostCN(slug: string, edicion: string): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-post-cn`,
        {
          edicion: edicion,
          slug: slug,
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  getPostsCN(edicion: string, page: number): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-posts-cn`,
        {
          edicion: edicion,
          user_id: user_id,
          page: page
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  getPostsCNMonth(perPage: number, page: number) {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-info-conexion-month`,
        {
          user_id: user_id,
          page: page,
          per_page: perPage
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  getRepositoryImages(category: string, perPage: number, page: number): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-info-images-repository`,
        {
          category: category,
          user_id: user_id,
          page: page,
          perPage: perPage,
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  getRepositoryImagesSingle(category: string): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/get-info-images-repository-single`,
        {
          category: category,
        }
      )
      .pipe((result) => {
        return result;
      });
  }
  uploadComment(
    post_id: number,
    author: string,
    author_email: string,
    author_id: number,
    content: string
  ): Observable<Record<string, any>> {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'));
      var user_id = parseInt(user['ID']);
    } else {
      var user_id = 0;
    }

    return this.httpClient
      .post(
        `${environment2.apiUrl}/activity/upload-comment/`,
        {
          post_id: post_id,
          author: author,
          author_email: author_email,
          author_id: author_id,
          content: content,
          user_id: user_id
        }
      )
      .pipe((result) => {
        return result;
      });
  }
}
