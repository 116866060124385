<!-- <div class="box-gif-event-sidebar">
  <a [routerLink]="['/techo']">
    <img src="assets/img/gif-techo.gif" alt="" class="img-fluid">
  </a>
</div> -->
<div class="box-profile" *ngIf="loggedIn">
  <div class="img-box-profile-box">
    <img src="assets/img/mask-profile.png" alt="">
    <div class="img-box-profile" [innerHTML]="avatar_logged"></div>
  </div>
  <div class="box-profile-content">
    <h3 class="title-box-profile">{{ name_logged }}</h3>
    <a [routerLink]="['/perfil']">Editar Perfil</a>
  </div>
</div>
<div class="box-proposito-sidebar">
  <!-- <h3 class="box-tols-title-custom box-proposito-sidebar-title">Proposito</h3> -->
  <a [routerLink]="['/proposito']">
    <img src="assets/img/banner-proposito-muro2.jpg" alt="" class="img-fluid">
  </a>
</div>
<div class="box-proposito-sidebar">
  <!-- <h3 class="box-tols-title-custom box-proposito-sidebar-title">Proposito</h3> -->
  <a [routerLink]="['/ley-karin']">
    <img src="assets/img/banner-ley-karin2.jpg" alt="" class="img-fluid">
  </a>
</div>
<div class="box-proposito-sidebar">
  <a [routerLink]="['/noticias-conexion-naranja']">
    <img src="assets/img/banner-seccion-intranet-v2.jpg" alt="" class="img-fluid">
  </a>
</div>
<!-- <div class="box-proposito-sidebar">
  <a [routerLink]="['/reciclar-es-colaborar']">
    <img src="assets/img/banner-minisitio.jpg" alt="" class="img-fluid">
  </a>
</div> -->
<!-- <div class="box-proposito-sidebar">
  <a href="https://forms.office.com/r/fjWFP6da8t" target="_blank">
    <img src="assets/img/banner-Integros.jpg" alt="" class="img-fluid">
  </a>
</div> -->
<div class="box-tols box-grupos-sidebar" *ngIf="loggedIn && grupos?.length >= 1">
  <h3 class="box-tols-title-custom box-tols-title-custom-grupo">Grupos</h3>
  <h3 class="title-aside">
    Grupos a los que perteneces:
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>
  <div class="box-items-grupos" *ngFor="let grupo of grupos; let i = index">
    <div class="box-item-grupos" [routerLink]="['/group/' + grupo.slug]" *ngIf="grupo.slug != 'grupo-de-testing'">
      <img [src]="grupo.img_grupo" alt="" />
      <h3 [innerHTML]="grupo.titulo"></h3>
    </div>
  </div>
</div>
<!-- <div class="box-tols content-collapse-menu-mobile" id="menu-mobile4">
  <div class="box-tols-coronavirus" [ngClass]="!loggedIn ? 'box-tols-cumpleanos-no-logged' : ''">
    <a href="#" class="btn-coronavirus" [routerLink]="['/noticias']"
      [queryParams]="{ categoryslug: 'coronavirus' }"><img src="assets/img/icon-coronavirus.svg"
        alt="" /><span>Infórmate sobre el Coronavirus aquí</span></a>
    <a [routerLink]="['/instructivos-coronavirus']" class="btn-coronavirus mb-0"><span>Revisa Instructivos
        AQUÍ</span></a>
  </div>
</div> -->
<!-- <div class="box-tols content-collapse-menu-mobile" id="menu-mobile4">
  <h3 class="title-aside title-aside-mobile">
    Coronavirus
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>
  <div class="box-item-punto-prensa" [routerLink]="['/noticia/' + item.id ]"
    *ngFor="let item of newsCoronavirus; let i = index">
    <img src="{{ item.img }}" alt="" class="img-item-punto-prensa">
    <h3 class="title-item-punto-prensa ">{{ item.title }}</h3>
  </div>
  <div class="box-buttons-corona">
    <a href="#" class="btn-coronavirus" [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'coronavirus' }"><img
      src="assets/img/icon-coronavirus.svg" alt=""><span>Infórmate sobre el
      Coronavirus aquí</span></a>
    <a [routerLink]="['/instructivos-coronavirus']" class="btn-coronavirus"><span>Revisa Instructivos AQUÍ</span></a>
  </div>
</div> -->
<!-- <div class="box-tols box-tols-history content-collapse-menu-mobile" id="menu-mobile1">
  <h3 class="title-aside">
    Historias
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>

  <div class="swiper-container list-box-history" id="history-carousel" [swiper]="historySwiper.config"
    [(index)]="historySwiper.index">
    <div class="swiper-wrapper">
      <div class="item-history add-history swiper-slide">
        <div class="box-item-history-img">
          <img src="assets/img/img-profile.png" class="img-box-profile" alt="" />
          <a href="#" class="btn-add-history">+</a>
        </div>
        <h6 class="title-history">Your Story</h6>
      </div>
      <div class="item-history swiper-slide">
        <img src="assets/img/brad.png" class="img-box-profile" alt="" />
        <h6 class="title-history">Brad</h6>
      </div>
      <div class="item-history swiper-slide">
        <img src="assets/img/jane.png" class="img-box-profile" alt="" />
        <h6 class="title-history">Jane</h6>
      </div>
      <div class="item-history swiper-slide">
        <img src="assets/img/frank.png" class="img-box-profile" alt="" />
        <h6 class="title-history">Frank</h6>
      </div>
      <div class="item-history swiper-slide">
        <img src="assets/img/frank.png" class="img-box-profile" alt="" />
        <h6 class="title-history">Frank</h6>
      </div>
      <div class="item-history swiper-slide">
        <img src="assets/img/frank.png" class="img-box-profile" alt="" />
        <h6 class="title-history">Frank</h6>
      </div>
    </div>

    <div class="swiper-button-prev">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </div>
    <div class="swiper-button-next">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </div>
  </div>
</div> -->
<div class="box-tols box-tols-cumpleanos content-collapse-menu-mobile" id="menu-mobile3">
  <h3 class="box-tols-title-custom box-tols-title-custom-cumple">Cumpleaños</h3>
  <h3 class="title-aside">
    <a [routerLink]="['/cumpleanos']">Cumpleaños
      <span>({{ cumpleanos[0].dia }} {{ cumpleanos[0].mes }})</span></a>
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>
  <div class="box-cumpleanos">
    <ul class="list-cumpleanos" *ngIf="cumpleanos[0].have_cumpleanos">
      <li *ngFor="let cumpleano of cumpleanos" [innerHTML]="renderTitleCumpleanos(cumpleano.post_title)"></li>
    </ul>
    <h3 class="title-box-cumpleanos" *ngIf="!cumpleanos[0].have_cumpleanos">Hoy no hay cumpleaños</h3>
    <img src="assets/img/globos.svg" alt="" class="img-cumpleanos" />
  </div>
</div>
<div class="box-tols box-punto-prensa content-collapse-menu-mobile" id="menu-mobile1">
  <h3 class="box-tols-title-custom box-tols-title-custom-prensa">Punto de Prensa</h3>
  <h3 class="title-aside">
    Punto de Prensa
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>
  <div class="box-item-punto-prensa box-item-punto-empresa-{{ i }}" [routerLink]="['/noticia/' + item.post_name]"
    *ngFor="let item of puntoPrensa; let i = index">
    <img src="{{ item.feature_img }}" alt="" class="img-item-punto-prensa" />
    <h3 class="title-item-punto-prensa">{{ item.post_title }}</h3>
  </div>
  <a [routerLink]="['/punto-de-prensa']" class="btn-sidebar">Ver más</a>
</div>
<div class="box-tols box-punto-prensa box-punto-prensa-eventos content-collapse-menu-mobile"
  *ngIf="eventos_actuales?.length > 0">
  <h3 class="box-tols-title-custom box-tols-title-custom-eventos-hoy">Eventos de hoy</h3>
  <h3 class="title-aside">
    Eventos de hoy
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>
  <div class="box-item-punto-prensa box-item-punto-empresa-{{ i }}" [routerLink]="['/evento/' + evento.ID]"
    *ngFor="let evento of eventos_actuales; let i = index">
    <img src="{{ evento.image }}" alt="" class="img-item-punto-prensa" />
    <h3 class="title-item-punto-prensa">{{ evento.title }}</h3>
    <p *ngIf="evento.fields.horario">
      <b>Horario:</b> {{ evento.fields.horario }}
    </p>
    <a href="{{ evento.fields.direccion }}" target="_blank" class="btn-sidebar">Unirse aquí</a>
  </div>
  <a [routerLink]="['/eventos']" class="btn-sidebar">Ver más</a>
</div>

<div class="box-tols box-punto-prensa box-punto-prensa-eventos content-collapse-menu-mobile"
  *ngIf="eventos_siguientes?.length > 0">
  <h3 class="box-tols-title-custom box-tols-title-custom-eventos-proximos">Proximos eventos</h3>
  <h3 class="title-aside">
    Proximos eventos
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>
  <div class="box-item-punto-prensa box-item-punto-empresa-{{ i }}" [routerLink]="['/evento/' + evento.ID]"
    *ngFor="let evento of eventos_siguientes; let i = index">
    <img src="{{ evento.image }}" alt="" class="img-item-punto-prensa" />
    <h3 class="title-item-punto-prensa">{{ evento.title }}</h3>
    <p *ngIf="evento.fields.horario">
      <b>Fecha:</b> {{ evento.fields.fecha }} <br />
      <b>Horario:</b>
      {{ evento.fields.horario }}
    </p>
    <!-- <a href="{{ evento.fields.direccion }}" target="_blank" class="btn-sidebar"
      >Unirse aquí</a
    > -->
  </div>
  <a [routerLink]="['/eventos']" class="btn-sidebar">Ver más</a>
</div>
<div class="box-tols box-tols-herramientas content-collapse-menu-mobile" id="menu-mobile2">
  <h3 class="box-tols-title-custom box-tols-title-custom-tools">Herramientas</h3>
  <h3 class="title-aside">
    Herramientas
    <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
  </h3>

  <div class="list-box-tols" id="list-box-tols">
    <!-- <a [routerLink]="['/canal-de-denuncias']">
      <img src="assets/img/icon-tool1.svg" alt="" />
      <h3 class="title-tols">Canal de Denuncias</h3>
    </a> -->
    <template *ngFor="let tool of tools; let i = index">
      <a [href]="tool.link" target="_blank" *ngIf="tool.type == 'externa'">
        <img [src]="tool.icon" alt="" />
        <h3 class="title-tols">{{tool.title}}</h3>
      </a>
      <a [routerLink]="[tool.link]" *ngIf="tool.type == 'interna'">
        <img [src]="tool.icon" alt="" />
        <h3 class="title-tols">{{tool.title}}</h3>
      </a>
    </template>
    <a></a>
    <a></a>
  </div>
</div>
<div id="modal-birthday" class="modal-birthday">
  <div class="overlay-modal overlay-modal-birthday"></div>
  <div class="bg-papelillos"></div>
  <div class="modal-content">
    <button type="button" class="close close-modal-birthday" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
      <h2 class="title-success">Feliz Cumpleaños!!</h2>
      <div class="box-modal-text">
        <p>Te deseamos lo mejor en tu día</p>
      </div>
    </div>
  </div>
</div>