<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Modelo Híbrido</span>
      </div>
    </div>
  </div>
</section>

<section class="section-custom-new3">
  <div class="container">
    <div class="section-custom-new3-container">
      <img src="assets/img/banner-modelo-hibrido.jpg" class="section-custom-new3-container-img" alt="">
    </div>
    <div class="text-center mb-3">
      <!-- <h1 class="title1 text-center mb-5">Modelo Híbrido</h1> -->
    </div>
    <div class="row">
      <div class="col-lg-6 mx-auto pb-5" *ngIf="documentos && documentos.length > 0">
        <h3 class="title-custom-new3" data-aos="fade-up" data-aos-duration="700">DOCUMENTOS</h3>
        <span class="line-title-custom-new" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100"></span>
        <div class="content-documents-custom-new3">
          <a href="{{ documento.link }}" class="item-document-custom-new3"
            *ngFor="let documento of documentos; let i = index" target="_blank" data-aos="fade-up"
            data-aos-duration="700" data-aos-delay="0">
            <h3 class="title-document-custom-new3">{{ documento.titulo }}</h3>
            <div class="box-acctions-custom-new3">
              <span class="view-document">
                <img src="assets/img/file.svg" alt="">
              </span>
              <span class="download-document">
                <img src="assets/img/download.svg" alt="">
              </span>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-11 mx-auto">
        <div class="tabs-search">
          <button data-tab="#news-compliance" class="item-tab-search active"
            *ngIf="news1 && news1.length > 0">Noticias</button>
          <button data-tab="#galeries-compliance" class="item-tab-search "
            *ngIf="galerias1 && galerias1.length > 0">Galería</button>
          <button data-tab="#videos-compliance" class="item-tab-search "
            *ngIf="galeriaCompliance && galeriaCompliance.length > 0">Videos</button>
        </div>
        <div class="tabs-content-search">
          <div class="item-tab-content-search" id="news-compliance" *ngIf="news1 && news1.length > 0">
            <section class="section-custom5">
              <div class="container">
                <div class="swiper-container" [swiper]="carouselGalerias.config">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let new1 of news1; let i = index">
                      <div class="card-design-notice card-design-embajadores" data-aos="fade-up"
                        data-aos-duration="700">
                        <div class="card-img">
                          <img src="{{ new1.img }}" alt="">
                          <div class="diagonal-cut"></div>
                        </div>
                        <div class="card-content">
                          <h4 class="card-title">{{ new1.title }}</h4>
                          <div class="card-text">
                            <p [innerHTML]="new1.text"></p>
                          </div>
                          <a [routerLink]="['/noticia/' + new1.slug ]" class="card-link">Leer más</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-button-prev">
                    <span class="material-icons">
                      keyboard_arrow_left
                    </span>
                  </div>
                  <div class="swiper-button-next">
                    <span class="material-icons">
                      keyboard_arrow_right
                    </span>
                  </div>
                </div>
                <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                  <a [routerLink]="['/noticias']" [queryParams]="{categoryslug: 'ley-karin' }" class="btn-custom-new">
                    <span>VER MÁS</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
          <div class="item-tab-content-search" id="galeries-compliance" *ngIf="galerias1 && galerias1.length > 0"
            style="display: none;">
            <section class="section-custom5">
              <div class="container">
                <div class="swiper-container" [swiper]="carouselGalerias2.config">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let item of galerias1; let i = index">
                      <a [routerLink]="['/galeria/' + item.ID ]" [queryParams]="{title: item.post_title }"
                        class="card-design-notice card-design-notice-nm card-design-embajadores" data-aos="fade-up"
                        data-aos="fade-up" data-aos-duration="700">
                        <div class="card-img">
                          <img src="{{ item.feature_img }}" alt="">
                          <div class="diagonal-cut"></div>
                        </div>
                        <div class="card-content">
                          <h4 class="card-title" [innerHTML]="item.post_title"></h4>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="swiper-button-prev">
                    <span class="material-icons">
                      keyboard_arrow_left
                    </span>
                  </div>
                  <div class="swiper-button-next">
                    <span class="material-icons">
                      keyboard_arrow_right
                    </span>
                  </div>
                </div>
                <div class="col-12 box-btn-news mt-5" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                  <a [routerLink]="['/galeria-image-section/ley-karin' ]" class="btn-custom-new">
                    <span>VER MÁS</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
          <div class="item-tab-content-search" id="videos-compliance"
            *ngIf="galeriaCompliance && galeriaCompliance.length > 0" style="display: none;">
            <section class="section-custom5">
              <div class="container">
                <div class="swiper-container" [swiper]="carouselGalerias3.config">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let item of galeriaCompliance; let i = index">
                      <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                        (click)="showModalVideo($event, item.video_url)" data-aos-duration="700" *ngIf="!item.youtube">
                        <div class="card-img">
                          <img src="{{ item.img }}" alt="">
                          <div class="diagonal-cut"></div>
                          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                            class="btn-play-desing1">
                        </div>
                        <div class="card-content">
                          <h4 class="card-title">{{ item.title }}</h4>
                        </div>
                      </div>
                      <div class="card-design-notice card-design-embajadores item-video-galeria" data-aos="fade-up"
                        (click)="showModalVideoIframe($event, item.youtube)" data-aos-duration="700"
                        *ngIf="item.youtube">
                        <div class="card-img">
                          <img src="{{ item.img }}" alt="">
                          <div class="diagonal-cut"></div>
                          <img src="https://intranet.abastible.cl/wp/wp-content/uploads/2021/04/btn-play.png" alt=""
                            class="btn-play-desing1">
                        </div>
                        <div class="card-content">
                          <h4 class="card-title">{{ item.title }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-button-prev">
                    <span class="material-icons">
                      keyboard_arrow_left
                    </span>
                  </div>
                  <div class="swiper-button-next">
                    <span class="material-icons">
                      keyboard_arrow_right
                    </span>
                  </div>
                </div>
                <div class="col-12 box-btn-news" data-aos="fade-up" data-aos-duration="700" data-aos-delay="100">
                  <a [routerLink]="['/galeria-videos-section/ley-karin' ]" [queryParams]="1" class="btn-custom-new">
                    <span>VER MÁS</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal-img-full" [ngClass]="modalImgShow == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeFullImgModal()"></div>
  <button class="close" (click)="closeFullImgModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-img-full">
    <!-- <img src="{{ modalImgSrc }}" alt=""> -->
  </div>
  <div class="navs-buttons">
    <button class="btn-prev-lightbox" *ngIf="has_prev_img" (click)="prevFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>

    <button class="btn-next-lightbox" *ngIf="has_next_img" (click)="nextFullImgModal()">
      <img src="assets/img/icons/arrow-white.svg" alt="" />
    </button>
  </div>
</div>

<div id="modal-video-youtube" class="modal-video" [ngClass]="modalVideoIframe == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideoIframe()"></div>
  <button class="close" (click)="closeModalVideoIframe()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <iframe width="560" height="315" [src]="urlSafe" class="iframe-video" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
</div>

<div id="modal-video" class="modal-video" [ngClass]="modalVideo == true ? 'active-modal' : ''">
  <div class="overlay-modal" (click)="closeModalVideo()"></div>
  <button class="close" (click)="closeModalVideo()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="box-modal-video-full">
    <video width="800" [src]="modalSrcVideo" controls>
      Your browser does not support HTML5 video.
    </video>
  </div>
</div>