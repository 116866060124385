<section class="banner-repositorio">
  <img src="/assets/img/banner-repositorio.jpg" alt="">
</section>

<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Repositorio de imágenes</span>
      </div>
    </div>
  </div>
</section>

<section class="page-repository" *ngIf="post">
  <div class="container">
    <div class="page-repository-container">
      <div class="page-repository-container-header">
        <h1 class="page-repository-container-header-title">
          <span>{{post.post.info.post_title}}</span>
          <!-- <span class="page-repository-container-header-title-tag">{{post.post.info.post_title}}</span> -->
        </h1>
      </div>
      <div class="page-repository-container-dropdowns">
        <div class="page-repository-container-dropdown"
          *ngFor="let subcategory of post.post.fields.subcategorias; let k = index">
          <button class="page-repository-container-dropdown-button">
            <span>{{subcategory.titulo}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-down">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 5l0 14" />
              <path d="M18 13l-6 6" />
              <path d="M6 13l6 6" />
            </svg>
          </button>
          <div class="page-repository-container-dropdown-content">
            <div class="page-repository-container-items">
              <div class="page-repository-container-item" *ngFor="let image of subcategory.galeria; let k = index">
                <div class="page-repository-container-item-img">
                  <img [src]="image.sizes.large" alt="">
                </div>
                <h3 class="page-repository-container-item-title">{{image.title}}</h3>
                <a [href]="image.url" download class="page-repository-container-item-btn btn-design1">
                  <span>Descargar</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-download">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                    <path d="M7 11l5 5l5 -5" />
                    <path d="M12 4l0 12" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>