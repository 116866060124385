<section class="banner-repositorio">
  <img src="/assets/img/banner-repositorio.jpg" alt="">
</section>

<section id="breadcrumbs">
  <div class="container">
    <div class="breadcrumbs">
      <div class="breadcrumbs-item">
        <a [routerLink]="['/muro']"><span class="material-icons">
            home
          </span></a>
        <img src="assets/img/arrow.svg" alt="" class="img-collapse" />
      </div>
      <div class="breadcrumbs-item">
        <span>Repositorio de imágenes</span>
      </div>
    </div>
  </div>
</section>

<section class="page-repository">
  <div class="container">
    <div class="page-repository-container">
      <div class="page-repository-container-header">
        <h1 class="page-repository-container-header-title">Repositorio de imágenes</h1>
      </div>
      <div class="page-repository-container-items">
        <div class="page-repository-container-item" *ngFor="let image of post.posts; let k = index">
          <div class="page-repository-container-item-img">
            <img [src]="image.fields.imagen.sizes.medium" alt="">
            <!-- <h6 class="page-repository-container-item-tag">{{image.taxonomy[0].name}}</h6> -->
          </div>
          <h3 class="page-repository-container-item-title">{{image.info.post_title}}</h3>
          <a [routerLink]="['/repositorio-de-imagenes/' + image.info.post_name]"
            class="page-repository-container-item-btn btn-design1">
            <span>Ver categoría</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l14 0" />
              <path d="M13 18l6 -6" />
              <path d="M13 6l6 6" />
            </svg>
          </a>
        </div>
      </div>
      <!-- <div class="page-section2-container-pagination-container">
        <a href="javascript:;" class="page-section2-container-pagination" (click)="setPage(i + 1)"
          *ngFor="let item of arrayPagination; let i = index" [ngClass]="{ 'active': i === (currentPage - 1) }">{{ i +
          1
          }} </a>
      </div> -->
    </div>
  </div>
</section>