import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

// Services
import { AuthService } from '@services/auth';
import { LandingService } from '@services/landing';
import { LoadingService } from '@services/loading';
import { NoticiasService } from '@services/noticias';
import { NewsService } from '@app/services/news';
import { GaleriasService } from '@app/services/galerias';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-trivia-enemigo-secreto',
  templateUrl: './trivia-enemigo-secreto.component.html',
  styleUrls: ['./trivia-enemigo-secreto.component.scss'],
})
export class TriviaEnemigoSecretoAbastibleComponent implements OnInit {
  params: any;
  titulo: any;
  slug: any;
  state: any;
  paramsID: any;
  selectedIndex: any[] = [];
  newsSeguridadInformacion: any;
  galeriaVideosSeguridad: any;
  galeriaSeguridad: any;
  news1: any;
  news1Res: any;
  currentPage1 = 1;
  catNews = "all";
  categoriesFilter: any;
  modalVideoIframe = false;
  modalSrcVideoIframe: string = "";
  urlSafe: SafeResourceUrl;
  carouselCustom = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 20,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  carouselCharlas2 = {
    index: 0,
    config: {
      direction: 'horizontal',
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      breakpoints: {
        // when window width is >= 320px
        1199: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 1,
        },
        570: {
          slidesPerView: 1,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  };
  modalVideo: any;
  modalSrcVideo: any;
  hasSubmit: Boolean = false;
  myForm: FormGroup;
  timeInterval: any;
  participo: Number = 3;
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private NoticiasService: NoticiasService,
    private galeriasService: GaleriasService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    private NewsService: NewsService,
    private title: Title
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.params = params
      this.init();
    });
    this.myForm = this.formBuilder.group({
      answer1: ['', [Validators.required]],
      answer2: ['', [Validators.required]],
    });
  }
  init(): void {
    this.title.setTitle('Trivia enemigo secreto | Intranet Abastible');
    this.paramsID = 29622;
    this.slug = this.params['slug'];
    let that = this;

    that.validateUserTrivia();

    $("body").delegate("#next-step", "click", function (e) {
      e.preventDefault();
      let validate = true;
      $('[name="answer1"]').removeClass('input-error');
      if ($('[name="answer1"]').val() == null || $('[name="answer1"]').val() == '') {
        validate = false;
        $('[name="answer1"]').addClass('input-error');
      }

      if (validate) {
        $("#trivia-form1, #trivia-title-form1").fadeOut(300);
        setTimeout(() => {
          $("#trivia-form2, #trivia-title-form2").fadeIn(300);
        }, 300);
        // setTimeout(() => {
        //   $('html, body').animate({
        //     scrollTop: $('.ancla-scroll1').offset().top
        //   }, 500);
        // }, 500);
      }
    });
    $("body").delegate("#submit-form", "click", function (e) {
      e.preventDefault();
      let validate = true;
      $('[name="answer2"]').removeClass('input-error');
      if ($('[name="answer2"]').val() == null || $('[name="answer2"]').val() == '') {
        validate = false;
        $('[name="answer2"]').addClass('input-error');
      }

      if (validate) {
        that.myForm.setValue({
          answer1: $("#answer1").val(),
          answer2: $("#answer2").val()
        });
        that.submitForm();
      }
    });

  }
  submitForm() {
    if (!this.hasSubmit) {
      this.hasSubmit = true;
      var that = this;
      const formData = new FormData();
      formData.append("answer1", that.myForm.get('answer1').value);
      formData.append("answer2", that.myForm.get('answer2').value);
      formData.append("user_id", that.authService.user['ID']);
      that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/trivia_secret_enemy_trivia', formData)
        .subscribe(res => {
          console.log(res);
          that.participo = 4;
          // setTimeout(() => {
          //   $('html, body').animate({
          //     scrollTop: $('.ancla-scroll2').offset().top
          //   }, 500);
          // }, 500);
        })
    }
  }
  validateUserTrivia() {
    var that = this;
    const formData = new FormData();
    formData.append("user_id", that.authService.user['ID']);
    that.http.post('https://intranet.abastible.cl/wp/wp-json/v2/validate_trivia_secret_enemy_trivia', formData)
      .subscribe(res => {
        if (res) {
          that.participo = 1;
        } else {
          that.participo = 0
        }
      })
  }
  functionInterval(clear: boolean = false) {
    let timeIntervalVar = 0;
    let that = this;
    if (clear) {
      clearInterval(this.timeInterval);
    } else {
      that.timeInterval = setInterval(() => {
        timeIntervalVar = timeIntervalVar + 1;
        $("#time_form").val(timeIntervalVar)
      }, 1000);
    }
  }

} 
